import axios from 'axios'
import FormData from 'form-data'
import { base64StringToBlob } from 'blob-util';
var file_api = {

  get(src) {
    return axios.get('/medias/' + src, { responseType: 'blob' })//this.img = res.data
      .then((res) => {

        let img = URL.createObjectURL(res.data)

        return new Promise((resolve) => { resolve(img) })
      })
      .catch((err) => {
        console.debug(err)
      })
  },

  post(file, name) {
    let data = new FormData();
    data.append('file', file);
    if (name != undefined && name != "") {
      data.append('media_filename', name)
    }

    return axios.post('/medias/'/*+src*/, data, { contentType: 'multipart/form-data' })
      .then((res) => {
        return new Promise((resolve) => resolve(res.data))
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
  },
  getMany(ids) {
    return axios.get('/medias/', {
      
      params: { ids }
    })
      .then((medias) => {
        var blobs = []
        medias.data.forEach(media => {
          blobs.push(new Promise((resolve) => { 
           resolve({_id : media._id, blob : URL.createObjectURL(new Blob([Buffer.from(media.media_data.data)], {type : media.media_mimetype}))})
          }))
        });
        return Promise.all(blobs)
        .then((res)=>{
          return Promise.resolve(res) 
        })/*
          let img = URL.createObjectURL(res.data)*/

     
      })
      .catch((err) => {
        console.debug(err)
      })
  },


  put(file, id, name) {
    let data = new FormData();
    data.append('file', file);
    if (name != undefined && name != "") {
      data.append('media_filename', name)
    }

    return axios.put('/medias/' + id, data, { contentType: 'multipart/form-data' })
      .then((res) => {
        return new Promise((resolve) => resolve(res.data))
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
  }
}
export default file_api