import snackbar from "node-snackbar/dist/snackbar.min"

snackbar.colorLevel = {
    0 : "#DE6264",//Error
    error : "#DE6264",//Error
    1 : "#F4D774",//warning
    warning : "#F4D774",//warning
    2 : "#8BE1B3",//Succes
    succes : "#8BE1B3",//Succes
    3 : "#cfcfcf",//Info 
    info : "#cfcfcf",//Info 
}
snackbar.backgroundColor = "#535556";
snackbar.duration = "3000"
snackbar.pos = "bottom-right"

export default snackbar