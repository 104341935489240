

/* NOT USED
//FUNCTION
function find(arr, test, ctx) {
    var result = null;
    arr.some(function(el, i) {
        return test.call(ctx, el, i, arr) ? ((result = el), true) : false;
    });
    return result;
}

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
*/
//LOADING = 0 : Not Loaded
//LOADING = 1 : Loading
//LOADING = 2 : Loaded
//LOADING = 3 : Error

import state from './state'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

export default {
    state,
    mutations,
    actions,
    getters
}