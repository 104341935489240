import axios from "axios"
import state from "./state"
import mutations from "./mutations"

export default {
    init_animationslist : ({ state, dispatch }) => {
        //if (state.animations.length < 1){ //If any animations is loaded, load animations list
            //console.debug("No animations loaded yet : Ask for fetching animations list")
            dispatch('fetch_animationslist')
        //}
    },
    fetch_animationslist : ({commit}) =>{
        //console.debug('fetch_animationslist')
        axios.get('/animations')
        .then((res)=>{
            var set = res.data.flatMap(animation => { return {loading : 2, ...animation} })
            commit('SET_ANIMATION_LIST', set)
        })
        .catch(err =>{
            console.error("[STORE] fetch_animationslist", err.response.data)
        })
    },
    fetch_animation : ({ state, commit}, animation_id) => {
        if (animation_id != null){
            return new Promise((resolve, reject) => {
    
                //SET ANIMATION AS LOADING
                var founded = false;
                state.animations.forEach((element, index) => {
                    if (element._id == animation_id) {
                        state.animations[index].loading = 1
                        founded = true
                    }
                })
                if (founded == false){
                    commit('SET_ANIMATION', {loading : 1, _id : animation_id })
                }
    
                //FETCH ANIMATION FROM API
                axios.get('/animations/' + animation_id)
                .then((res)=>{
                    var set = { loading : 2, ...res.data }
                    commit('SET_ANIMATION', set )
                    resolve(set)
                })
                .catch(err =>{
                    commit('SET_ANIMATION', { loading : 3, ...res.data } )
                    reject("[STORE] fetch_animation", err.response.data)
                })
            })
        } else {
            return new Promise.reject()
        }
    },
    get_animation : ({ state, dispatch }, animation_id) => {
        if (animation_id != null){

            //console.log("Get animation "+animation_id, state.animations)
            return new Promise((resolve, reject) => {
                var animationFounded = state.animations.filter(animation =>{
                    if (animation._id == animation_id){
                        return true
                    } else {
                        return false
                    }
                })
    
                var haveToFetch = false
    
                if (animationFounded.length == 0){
                    //console.debug('No Animation founded, fetching it !')
                    haveToFetch = true;
                } else if (animationFounded[0].loading == 1){ //If loading wait until animation is loaded
                    (async ()=>{
                        var animation_intervaler = setInterval(function(){
                            //console.debug("Animation is loading, waiting...")
                            state.animations.forEach(animation =>{
                                if (animation._id == animation_id){
                                    if (animation.loading == 2){
                                        haveToFetch = false
                                        //console.debug("Animation "+animation_id+" loaded")
                                        clearInterval(animation_intervaler)
                                        resolve(animation)
                                    } else if (animation.loading == 3){
                                        haveToFetch = true
                                        clearInterval(animation_intervaler)
                                    }
                                }
                            })
                        }, 1000) 
                        
                        
                    })()
                } else if (animationFounded[0].loading == 2){ // LOADED
                    //console.debug("Good news, Animation loaded yet !")
                    resolve(animationFounded[0])
                }
    
                if (haveToFetch == true){
                    dispatch("fetch_animation", animation_id)
                    .then(animation =>{
                        resolve(animation)
                    })
                    .catch(error =>{
                        console.error(error)
                    })
                }
            })
        }
    },
    
    //ANIMATION MODIFIER FUNCTION
    fetch_animationModifierFunction : ({ state, commit }) => {
        //FETCH FUNCTION FROM API
        if (state.animationModifierFunction == null){
            return axios.get('/animations/modifierFunction')
            .then((res)=>{
                state.animationModifierFunction = new Function("params", res.data )
                //commit('SET_ANIMATION_MODIFIER_FUNCTION', res.data )
                Promise.resolve()
            })
            .catch((err) =>{
                console.error(err)
                Promise.reject("Impossible de fetch la modifierFunction")
            })
        }
    },

    fetch_default_animations : ({state, commit}, anim_type) => {
        /*
        var promiseList = [
            axios.get('/animations?anim_type=0&limit=1'),
            axios.get('/animations?anim_type=1&limit=1'),
            axios.get('/animationss?anim_type=&limit=1')
        ]

        return Promise.all(promiseList)
            .then((promisesRes)=>{
                promisseRes.forEach(promiseRes =>{
                    if (res.data.length != 0 && res.data[0] && res.data[0].anim_type == anim_type){
                        return Promise.resolve(res.data[0]._id)
                    }
                })
            }).catch(err =>{
                console.error('[Animation Store] : Impossible de récupérer les animations par défault', err)
            })
            */
        return axios.get(`/animations?anim_type=${anim_type}&limit=1`)
        .then(res =>{
            if (res.data.length != 0 && res.data[0]){
                return Promise.resolve(res.data[0]._id)
            }
        })

        /*
        .then((res)=>{
            commit('SET_ANIMATION', ...res.data )
            return Promise.resolve()
        })
        axios.get('/animations?anim_type=1&limit=1')
        .then((res)=>{
            commit('SET_ANIMATION', ...res.data )
            return Promise.resolve()
        })
        axios.get('/animations?anim_type=2&limit=1')
        .then((res)=>{
            commit('SET_ANIMATION', ...res.data )
            return Promise.resolve()
        })
        */
    }
    
}