import Vuex from 'vuex'

import animation from "./modules/animations"
import font_animation from './modules/font_animation';
import videos from "./modules/videos"
import auth from "./modules/auth"
import timelineTemplate from './modules/timelineTemplate';
/*
const dataState = createPersistedState({
  paths: [ 'user']
})*/
export default new Vuex.Store({

  modules: {
    auth,
    animation, 
    font_animation,
    videos,
    timelineTemplate
  },

})