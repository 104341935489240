
import state from './state'

export default {
    SET_FONTS_NAMELIST : (state, font_list) =>{
        state.fonts = font_list
    },
    SET_FONT : (state, newFont) =>{
        var founded_emplacement = null;
        var fontsFounded = state.fonts.filter((font, index)=>{
            if (font._id == newFont._id){
                founded_emplacement = index
                return true
            } else {
                return false
            }
        })

        if (fontsFounded.length != 0){
            state.fonts[founded_emplacement] = newFont
        } else {
            state.fonts.push(newFont)
        }
    }
}