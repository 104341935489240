
import state from './state'

export default {
    SET_ANIMATION_LIST : (state, animation_list) =>{
        state.animations = animation_list
    },
    SET_ANIMATION : (state, newAnimation) =>{
        var founded_emplacement = null;
        var animationsFounded = state.animations.filter((animation, index)=>{
            if (animation._id == newAnimation._id){
                founded_emplacement = index
                return true
            } else {
                return false
            }
        })

        if (animationsFounded.length != 0){
            state.animations[founded_emplacement] = newAnimation
        } else {
            state.animations.push(newAnimation)
        }
    },
    
    
    //SET_ANIMATION_MODIFIER_FUNCTION : (state, newFunction) => {
        //console.log("[STORE] SET_ANIMATION_MODIFIER_FUNCTION")
        //var newFunctionClean = newFunction.replace(/module\.exports.*/, "return main")
        //state.animationModifierFunction = new Function("params", newFunction)
    //}
    
}