import axios from "axios"
import state from './state'

export default {
    init_font_animation_name_list : ({ commit, state, dispatch }) => {
        //TODO: HOW TO VERIFY IF LIST IS COMPLETE
        /*
        if (state.fonts.length == 0){ //If any font is loaded, load font list without theire data font
            //console.debug("No font loaded yet : Ask for fetching font name list")
        }*/
        return dispatch('fetch_font_name_list')
    },
    fetch_font_name_list : ({ commit, state }) =>{
        state.fonts_loading = true
        return axios.get('/animations/font', {params : {charsData : false}})
        .then((res)=>{
            state.fonts_loading = false
            var set = res.data.flatMap(font => { return {loading : 2, ...font} })
            commit('SET_FONTS_NAMELIST', set)
            return Promise.resolve(set)
        })
        .catch(err =>{
            state.fonts_loading = false
            console.error(err.reponse.data)
        })
    },
    get_font_animation : ({commit}, font_id) =>{
        return new Promise((resolve, reject) => {

            //FIND COMPLETE FONT OR SET ANIMATION AS LOADING
            state.fonts.forEach((font, index) => {
                if (font._id == font_id) {
                    if (font.loading == 2 && font.font_charsData != undefined){
                        resolve(font)
                    } else {
                        state.fonts[index].loading = 1
                    }
                }
            })
            
            //FETCH ANIMATION FROM API
            commit('SET_FONT', {loading : 1, _id : font_id })
            axios.get('/animations/font/' + font_id)
            .then((res)=>{
                var set = { loading : 2, ...res.data }
                commit('SET_FONT', set )
                resolve(set)
            })
            .catch(err =>{
                commit('SET_FONT', { loading : 3, _id : font_id } )
                reject("[STORE] fetch_font_animation", err.reponse.data)
            })
        })
    },
    fetch_font_animation : ({ commit, state, dispatch }, font_id) =>{
        return new Promise((resolve, reject) => {
            if ((font_id != undefined) && (font_id != null) && (typeof(font_id) == "string")){

                var fontFounded = state.fonts.filter(font =>{
                    if (font._id == font_id){
                        return true
                    } else {
                        return false
                    }
                })
    
                var haveToFetch = false

                if (fontFounded.length == 0){
                    //console.debug('No Animation founded, fetching it !')
                    haveToFetch = true;
                } else if (fontFounded[0].loading == 1){ //If loading
                    (async ()=>{
                        
                        var font_intervaler = setInterval(function(){
                            //console.log("Animation is loading, waiting...")
                            state.fonts.forEach(font =>{
                                if (font._id == font_id){
                                    if (font.loading == 2){
                                        clearInterval(font_intervaler)
                                        resolve(font)
                                    } else if (font.loading == 3){
                                        haveToFetch = true
                                        clearInterval(font_intervaler)
                                    }
                                }
                            })
                        }, 1000) 
                        
                        
                    })()
                } else if (fontFounded[0].loading == 2){ // LOADED
                    //console.debug("Good news, Font Animation loaded yet !")
                    resolve(fontFounded[0])
                }
    
                if (haveToFetch == true){
                    dispatch("fetch_font_animation", font_id)
                    .then(font =>{
                        resolve(font)
                    })
                    .catch(error =>{
                        console.error(error)
                    })
                }
                    
            } else {
                //console.log("NO FONT ID GIVED, BUT FONT NEEDED")
                if (state.fonts.length > 0){
                    resolve(state.fonts[0])
                } else {
                    (async ()=>{
                        await dispatch('fetch_font_name_list')
                        dispatch('get_font_animation', state.fonts[0]._id)
                        .then(font => resolve(font))
                        .catch(err => reject(err))
                    })()
                }
            }
        })
    }  
}