import { createApp } from 'vue'
import VueCookies from 'vue3-cookies'
import VuePlyr from "./components/Object/vue-plyr"
import './components/Object/vue-plyr/dist/vue-plyr.css'

/*
const stripeOptions = {
  pk: process.env.STRIPE_PUBLISHABLE_KEY,
  //stripeAccount: process.env.STRIPE_ACCOUNT,
  //apiVersion: process.env.API_VERSION,
  //locale: process.env.LOCALE,
};
*/

// AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'
//axios.defaults.baseURL = (process.env.NODE_ENV == "development" ? 'http://127.0.0.1:3200' : 'https://api-yopbox-alpha.herokuapp.com');
//axios.defaults.baseURL = (process.env.NODE_ENV == "development" ? 'http://127.0.0.1:3200' : process.env.API_URL) ;
axios.defaults.baseURL = process.env.API_URL

import router from './router'
import store from './store'

//APP
import App from './App.vue'
document.title = 'App Yopbox'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueCookies)
app.use(VuePlyr, {
    plyr: {}
  })
//app.use(StripePlugin, stripeOptions);
app.mount('#app')

  


