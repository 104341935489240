export default {
    animations : (state) => (anim_type) => {
        //console.debug('[STORE] anim_type = ' + anim_type)
        return state.animations.filter(animation =>{
            if (anim_type != undefined) {
                if (anim_type == 2 && (animation.anim_type == anim_type ||  animation.anim_type == 0)){
                    return true
                } else if (animation.anim_type == anim_type){
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        })
    },
    animation : (state) => (animation_id/*, animation_type*/) => {
        return state.animations.filter(animation=>{
            if (animation._id == animation_id){
                return true
            } else {
                return false
            }
        })
    },
    animation_Id_byType : (state) => (animation_type) => {
        const result = state.animations.find(animation => 
            animation.anim_type == animation_type )
        
        if (result != undefined){
            return result.id
        } else {
            return null
        }
        
    },

    
    //ANIMATION MODIFIER FUNCTION
    animationModifierFunction : ( state ) => {
        if (state.animationModifierFunction == null){
            console.warn('[STORE Animation] animationModifierFunction not available')
        }
        return state.animationModifierFunction
    }
    
}