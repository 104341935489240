export default [
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../views/Admin/Admin.vue'),
        meta : { required_role : 0 },
        children: [
          {
            path:'user',
            name:'Admin.user',
            component: () => import('../views/Admin/subViews/User.vue')
          },
          {
            path:'speaker',
            name:'Admin.speaker',
            component: () => import('../views/Admin/subViews/Speaker.vue')
          },
          {
            path:'back',
            name:'Admin.back',
            component: () => import('../views/Admin/subViews/Back.vue')
          },{
            path:'animation',
            name:'Admin.animation',
            component: () => import('../views/Admin/subViews/Animation/Animation.vue')
          },{
            path:'font_animation',
            name:'Admin.font_animation',
            component: () => import('../views/Admin/subViews/FontAnimation/FontAnimation.vue')
          },
          {
            path:'videos',
            name:'Admin.videos',
            component: () => import('../views/Admin/subViews/Videos/Videos.vue'),
          }
          ,
          {
            path:'commentary',
            name:'Admin.commentary',
            component: () => import('../views/Admin/subViews/Commentary.vue'),
          }
          ,
          {
            path:'job',
            name:'Admin.job',
            component: () => import('../views/Admin/subViews/Job/Job.vue'),
          },
          {
            path:'subtitles',
            name:'Admin.subtitles',
            component: () => import('../views/Admin/subViews/Subtitles.vue'),
          },
          {
            path:'music',
            name:'Admin.music',
            component: () => import('../views/Admin/subViews/Music.vue'),
          }
        ] 
        
    }
]