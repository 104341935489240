export default {
    getVideos: (state) => (vdo_state_or_list_id)=>{
        if (Array.isArray(vdo_state_or_list_id)){
            return state.videos.filter(video => vdo_state_or_list_id.includes(video._id))
        } else {
            var vdo_state = vdo_state_or_list_id
            if (vdo_state == "created" || vdo_state == 0) {
                 return state.videos.filter(vdo=> vdo.vdo_state == undefined ||vdo.vdo_state.state_ordered == undefined || vdo.vdo_state.state_ordered == null )
                
            } else if (vdo_state == "ordered"|| vdo_state == 1) {
               return state.videos.filter(vdo=>( vdo.vdo_state != undefined && vdo.vdo_state.state_ordered != undefined && vdo.vdo_state.state_ordered != null )
                && (vdo.vdo_state.state_delivred == undefined || vdo.vdo_state.state_delivred == null))
               
            } else if (vdo_state == "delivred" || vdo_state == 2) {
                //GET ALL VIDEO DELIVRED OR IN REVIEW EDITING OR JUST HAVE A JOB CREATED
                return state.videos.filter(vdo=>vdo.vdo_state != undefined && vdo.vdo_state.state_delivred != undefined && vdo.vdo_state.state_delivred != null || vdo.vdo_state.state_review_editing != undefined && vdo.vdo_state.state_review_editing != null || vdo.vdo_state.state_job_created != undefined && vdo.vdo_state.state_job_created != null)
                .sort((a,b) => Date.parse(a.vdo_state.state_delivred) - Date.parse(b.vdo_state.state_delivred))
                .reverse()
            }
            else {
                return state.videos
            }
        }
    } ,
    getVideoUserId: state =>state.usr_id,
    getAllVideos: state =>state.videos,
    getCreatedVideos(state){
        return state.videos.filter(vdo=>vdo.vdo_state.state_delivred != undefined && vdo.vdo_state.state_delivred != null)
    },
    getSelected_ids: state => state.selected_ids,
    getVdo_errors: state => state.vdo_errors,
    vdoHasSubitles: (state)=> (id)=>{
        let video = state.videos.find(vdo=>vdo._id == id)
        if(video.vdo_result.subtitles != undefined){
            return true
        }else{
            return false
        }
    }
}