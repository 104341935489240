import axios from 'axios'

//LIBS
import snackbar from "./../../../components/Object/snackbar"

//STATE
import state from "./state"

//ROUTER
import router from "./../../../router/index"

//API
import file_api from "../../../api/file"

export default {
    set_usr_id: ({ commit }, usr_id) => {
        commit('SET_USR_ID', usr_id)
    },
    loadVideos: ({ commit }) => {
        return axios
            .get("/videos/", { params: { usr_id: state.usr_id } })
            .then((res) => {
                commit('GET', res)
                commit('VERIF_ERRORS', res.data.map(video => { return video._id }));

                return res.data
            })
    },
    getOneVideo: ({ commit }, data) => {

        return axios
            .get("/videos/" + data.id, { params: data.query })
            .then((res) => {
                commit('UPDATE', res.data)
                return res.data
            })
    },
    addVideo: ({ commit }) => {
        axios
            .post("/videos", {
                usr_id: state.usr_id,
                vdo_name: "",
            })
            .then((res) => {
                commit('ADD', res.data)
                commit('VERIF_ERRORS', state.videos.map(video => { return video._id }))
            }).catch((err) => {
                console.error(err)
                snackbar.show({
                    text: "Impossible d'ajouter une nouvelle vidéo",
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["error"],
                });
            })
    },
    updateVideo: ({ commit }, data) => {
        var url = "/videos"
        if (data.queryParams) {
            url += `?${Object.keys(data.queryParams).map((key, keyIndex) => {
                if (keyIndex < Object.keys(data.queryParams).length - 1) {
                    return key + "=" + data.queryParams[key] + "&"
                } else {
                    return key + "=" + data.queryParams[key]
                }
            })}`
        }
        return axios
            .put(url, data.update)
            .then((res) => {
               

                commit('PUT', { res: res.data, collect_objs: data.collect_objs })
                commit('VERIF_ERRORS', state.videos.map(video => { return video._id }));
                //commit('SET_SELECTED_VDO', [])
                return res.data
            })
            .catch((err) => {
                console.error(err)
                snackbar.show({
                    text: err.response.data,
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["error"],
                });
                return Promise.reject(err.response.data)
            });
    },
    deleteVideo: ({ commit }, vdo_id) => {
        axios.delete("/videos/" + vdo_id).then(() => {
            commit('DELETE', vdo_id)
        });
    },
    duplicateVideo: ({ commit }, vdo_id) => {
        let index = state.videos.findIndex((vdo) => vdo._id == vdo_id);

        axios
            .post("/videos/", {
                usr_id: state.usr_id,
                ident_id: state.videos[index].ident_id,
                spk_id: state.videos[index].spk_id,
                music_id: state.videos[index].music_id,
                back_id: state.videos[index].back_id,
                vdo_name: "Copie - " + state.videos[index].vdo_name,
                vdo_timeline: state.videos[index].vdo_timeline.flatMap(clip => { delete clip._id; return clip }),
                vdo_duration: state.videos[index].vdo_duration,
            })
            .then((res) => {
                commit('ADD', res.data)
            })
            .catch(() => {
                snackbar.show({
                    text: "Echec de la duplication de la vidéo",
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["error"],
                });
            });
    },

    //[HOME CREATION PAGE] - VIDEOS SELECTION
    addSelectedVdo: ({ commit }, vdo_id) => {
        commit('ADD_OR_REMOVE_SELECTED_VDO', vdo_id)
    },
    setSelectedVdo: ({ commit }, vdo_ids) => {
        if (!Array.isArray(vdo_ids)) {
            vdo_ids = [vdo_ids]
        }
        commit('SET_SELECTED_VDO', vdo_ids)
    },


    putManyInProd: ({ commit, rootState, dispatch }, ids) => {
        if (!Array.isArray(ids)) {
            ids = [ids]
        }

        state.vdo_errors.global = [];
        commit('SET_SELECTED_VDO', ids)

        if (state.selected_ids.length <= 0) {
            return Promise.reject("Veuillez sélectionner au moins une video")
        }

        //VERIFY THERE IS NO ERROR ON VIDEOS
        commit('VERIF_ERRORS', state.selected_ids);
        if (state.vdo_errors.global.length != 0) {
            commit('VERIF_ERRORS', state.videos.map(video => { return video._id }))
            /*
            snackbar.show({
                text: "Une ou plusieurs vidéos incomplètes",
                pos: snackbar.pos,
                showAction: false,
                backgroundColor: snackbar.backgroundColor,
                duration: snackbar.duration,
                textColor: snackbar.colorLevel["error"],
            });
            */
            return Promise.reject("Une ou plusieurs vidéos incomplètes")


            //IF NO CREDIT ENOUGHT
        } else if (rootState.auth.user.usr_credit <= 0 && rootState.auth.user.usr_role != 0) {
            snackbar.show({
                text: "Vous n'avez pas assez de crédit pour commander",
                pos: snackbar.pos,
                showAction: false,
                backgroundColor: snackbar.backgroundColor,
                duration: snackbar.duration,
                textColor: snackbar.colorLevel["error"],
            });
            router.push({ path: "/profile/billing" });
            return Promise.reject("Une ou plusieurs vidéos incomplètes")
        } else {
            commit('VERIF_ERRORS', state.videos.map(video => { return video._id }))
            return axios
                .post("/videos/order", {
                    ids: state.selected_ids,
                    vdo_state: {
                        state_ordered: new Date()
                    }
                },
                    {
                        timeout: 15000
                    })
                .then((res) => {
                    var videosUpdated =  (Array.isArray(res.data) == false && typeof (res.data) == "object")?[res.data]:res.data
                    const videoCount = videosUpdated.length
                    commit("UPDATE", videosUpdated)
                    dispatch('refresh_user_info')
                    snackbar.show({
                        text: `${videoCount} vidéo${(videoCount > 1 ? 's' : '')} envoyée${(videoCount > 1 ? 's' : '')} en tournage`,
                        pos: snackbar.pos,
                        showAction: false,
                        backgroundColor: snackbar.backgroundColor,
                        duration: snackbar.duration,
                        textColor: snackbar.colorLevel["succes"],
                    });
                    commit('SET_SELECTED_VDO', [])
                    return Promise.resolve()
                })


        }


    },
    putManyToFirstStage: ({ commit }, id) => {

        state.vdo_errors.global = [];

        if (id == undefined && state.selected_ids.length < 2) {
            snackbar.show({
                text: "Veuillez sélectionner au moins une video",
                pos: snackbar.pos,
                showAction: false,
                backgroundColor: snackbar.backgroundColor,
                duration: snackbar.duration,
                textColor: snackbar.colorLevel["error"],
            });
        } else {
            let list_id = []
            if (id != undefined) {

                list_id.push(id)
                commit('VERIF_ERRORS', [id]);

            } else {
                list_id = state.selected_ids
                commit('VERIF_ERRORS', state.selected_ids);

            }

            if (state.vdo_errors.global.length == 0) {

                axios
                    .put("/videos/", {
                        ids: list_id,
                        vdo_state: {
                            state_ordered: null,
                            state_ordered: null,
                            state_shot: null,
                            state_job_created: null,
                            state_delivred: null
                        }
                    })
                    .then((res) => {
                        commit('UPDATE', res.data)
                        snackbar.show({
                            text: "Vidéo(s) renvoyée(s) en création",
                            pos: snackbar.pos,
                            showAction: false,
                            backgroundColor: snackbar.backgroundColor,
                            duration: snackbar.duration,
                            textColor: snackbar.colorLevel["succes"],
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                state.vdo_errors = {
                    vdo_name: [],
                    vdo_timeline: [],
                    ident_id: [],
                    back_id: [],
                    spk_id: [],
                    global: [],
                };
            } else {
                snackbar.show({
                    text: "Une ou plusieurs vidéos incomplètes",
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["error"],
                });
            }

        }

    },
    shootingDone: ({ commit }, vdo_id) => {
        if (vdo_id != undefined) {
            axios
                .post("/videos/" + vdo_id + "/shot")
                .then((res) => {
                    commit("UPDATE", res.data)
                    //console.log(res.data)
                })
        }
    },
    unsetField_video: ({ commit }, data) => {
        commit("UNSET_FIELD", data)

    },
    loadMedias: ({ commit }) => {
        let medias_needed = []
        state.videos.forEach((vdo) => {
            if (vdo.identity != undefined && vdo.identity.blob == undefined && !medias_needed.includes(vdo.identity.ident_logo)) {
                medias_needed.push(vdo.identity.ident_logo)
            }

            if (vdo.background != undefined && vdo.background.blob == undefined && !medias_needed.includes(vdo.background.back_thumbnail)) {
                medias_needed.push(vdo.background.back_thumbnail)
            }
            if (vdo.speaker != undefined && vdo.speaker.blob == undefined && !medias_needed.includes(vdo.speaker.spk_thumbnail)) {
                medias_needed.push(vdo.speaker.spk_thumbnail)
            }

        })
        file_api.getMany(medias_needed)
            .then((medias) => {
                commit("ASSIGN_MEDIAS", medias)
            })
    },
    refreshRemaingCredit(usr_id) {
        if (usr_id == undefined) {
            usr_id == state.usr_id
        }
        if (usr_id != undefined) {
            axios.get(`/users/${usr_id}`)
                .then((res) => {
                    console.log(res.data)
                })
        }
    },
    subtitles_request({ commit }, data) {

        return axios.post('/subtitles/' + data.vdo_id, { language: data.language })
            .then((res) => {

                commit('PUT', { res: [res.data] })

                snackbar.show({
                    text: "Vos sous titres vont être générés",
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["succes"],
                });
                return res.data
            })
    },

    subtitles_validation({ commit }, data) {
        return axios.get('/subtitles/' + data.vdo_id + '/' + data.language)
    }
};