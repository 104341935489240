import { createRouter, createWebHistory } from 'vue-router'
import admin_routes from './admin.js'
import profile_routes from './profile'
import snackbar from "./../components/Object/snackbar"

import store from "./../store"

//PARSE COOKIE FUNCTION
const parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'App Yopbox - Accueil',
    },
    component: () => import('../views/Home/Home.vue'),
    redirect: '/creation',
    children: [
      {
        path:'creation',
        name:'creation',
        component: () => import('../views/Home/Videos_creation/video_creation.vue')
      },
      {
        path:'shootings',
        name:'shootings',
        component: () => import('../views/Home/Videos_shootings/video_shootings.vue')
      },
      {
        path:'videos',
        name:'videos',
        component: () => import('../views/Home/Videos_delivred/Videos_delivred.vue')
      }
    ],
    beforeEnter: (to, from, next) => {
      //IF NO TOKEN
      if (store.getters['getToken'] == null) {
        next({
          name: 'Login',
          query : to.query //redirect to login with query
        })
      } else if (store.getters['getCurrentUsrId'] != undefined || store.getters['getCurrentUsrId'] != null){
        
        //IF QUERY REDIRECT ON LOGGED => Redirect with potential redirectQuery requested
        if (to.query.redirectOnLogged){
          
          let redirectionQuery = "";
          if (to.query.redirectQuery == "user_id") {
            redirectionQuery = `?user_id=${store.getters['getCurrentUsrId']}`
          }

          //ADD POTENTIAL QUERY ANNUAL
          if (to.query.annual) {
            redirectionQuery += `&annual=${to.query.annual}`
          }

          console.debug(`[Router] Redirect to ${to.query.redirectOnLogged}${redirectionQuery}`)
          window.location.href = `${to.query.redirectOnLogged}${redirectionQuery}`

        } else {
          next()
        }
      } else {
          next({
            name: 'Login',
            query : to.query //redirect to login with query
        })
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Bienvenu sur chez Yopbox',
    },
    component: () => import('../views/Login/Login.vue'),
    beforeEnter: (to, from, next) => {
      //IF NO TOKEN
      if (store.getters['getToken'] == null) {
        next()
      } else if (from.name == "/Home"){
        next()
      } else {
        next("/")
      }
    }
  },
  {
    path: '/identity',
    name: 'Chartes Graphiques',
    meta: {
      title: 'App Yopbox - Chartes Graphiques',
    },
    component: () => import('../views/Identity/Identity.vue'),
  },
  {
    path: '/error',
    name: 'Erreur',
    component: () => import('../views/error.vue'),
  },
  ...profile_routes,
  ...admin_routes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active", //add "active" class on <router-link> current active
})


//Redirect for unkowned paths
router.beforeEach((to, from, next) => {
  var availableRoutes = routes.flatMap(route => {
    if (route.children) {
      var set = route.children.map(children => {
        if (route.path != "/"){
          return route.path + "/" + children.path
        } else {
          return "/" + children.path
        }
      })
      set.push(route.path)
      return set
    } else {
      return route.path
    }
  })
  if (to.path != "/" && availableRoutes.includes( to.path.replace(/\/$/, "") ) == false) {
    console.warn('Unknow path')
    next("/")
  } else {
    next()
  }
})


//ROLE VERIFICATION
router.beforeEach(async (to, from, next) => {

  const parsedCookie = parseCookie(document.cookie)
  if (parsedCookie.token != undefined && parsedCookie.token != null && store.getters['getToken'] == null){
    //[WELCOME BACK !] No token in store BUT Token found in cookie =>
    //                                             1. Set token in store, and ping api to get
    //                                             2. Ping api to get usr_id (/auth/ping)
    //                                             3. Set usr_id in store and "refresh_user_info"
    await store.dispatch('storeTokenFromCookie', parsedCookie.token)
  }
  
  if (to.meta.required_role != undefined) {

    if (store.getters['isAdmin']) {
      next()
    } else if (store.getters['getCurrentUser'].usr_role == to.meta.required_role) {
      next()
    } else {
      snackbar.show({
        text: "Vous n'avez pas les autorisations necessaire",
        pos: snackbar.pos,
        showAction: false,
        backgroundColor: snackbar.backgroundColor,
        duration: snackbar.duration,
        textColor: snackbar.colorLevel["warn"],
      });
      next({ name: 'Home' })
    }
  } else {
    next()
  }
  
})



export default router