//LIBS
import snackbar from "./../../../components/Object/snackbar"
import verifGlobal from "../../../lib/verifGlobal"

export default {
  SET_USR_ID: (state, usr_id) => {
    state.vdo_errors = {
      vdo_name: [],
      vdo_timeline: [],
      ident_id: [],
      back_id: [],
      spk_id: [],
      music_id: [],
      global: []
    }
    state.usr_id = usr_id
  },
  GET: (state, res) => {
    state.videos = res.data;
  },
  DELETE: (state, vdo_id) => {
    state.videos = state.videos.filter((vdo) => vdo._id != vdo_id);
    state.selected_ids = state.selected_ids.filter((id_select) => id_select != vdo_id);

  },
  ADD: (state, data) => {
    state.videos.push(data)

  },
  PUT: (state, data) => {
    
    var res = (Array.isArray(data.res) == false && typeof (data.res) == "object")?[data.res]:data.res
  
    var collect_objs = data.collect_objs

    res.forEach((vdo) => {
      let index = state.videos.findIndex((video) => video._id == vdo._id)
      if(index != '-1'){
        for (let key in vdo) {
          state.videos[index][key] = vdo[key]
        }
        if (collect_objs != undefined) {
          for (let key in collect_objs) {
            state.videos[index][key] = collect_objs[key]
          }
        }
      }
    })

   
  },
  UPDATE: (state, vdo_list) => {

    if (vdo_list != undefined) {
      //Transform object vdo in list (for feat with foreach function below)
      if (Array.isArray(vdo_list) == false && typeof (vdo_list) == "object") {
        vdo_list = [vdo_list]
      }

      var vdo_not_found = vdo_list

      vdo_list.forEach(vdo => {
        //Find vidéo in state
        state.videos.forEach((vdo_from_state) => {
          if (vdo_from_state._id == vdo._id) {

            //DELETE VDO FROM vdo_not_found LIST
            var index = vdo_not_found.findIndex(videoFrom_vdo_not_found_list => videoFrom_vdo_not_found_list._id == vdo_from_state._id);
            if (index != -1){
              vdo_not_found.splice(index,1);
            }

            //REPLACE AND UPDATE EACH KEY OF VIDEO IN STATE
            Object.keys(vdo).forEach(key => {
              vdo_from_state[key] = vdo[key]
            })
          }
        })
      })

      //IF VIDEO NOT FOUND => ADD VIDEO TO STATE
      if (vdo_not_found.length > 0) //console.debug('[STORE VIDEO MUTATION] vdo_not_found for update (adding it): ', vdo_not_found)
      state.videos.push(...vdo_not_found)
    }
  },
  ADD_OR_REMOVE_SELECTED_VDO: (state, vdo_id) => {
    let index = state.selected_ids.findIndex((elem) => elem == vdo_id);
    if (index == "-1") {
      state.selected_ids.push(vdo_id);
    } else {
      state.selected_ids.splice(index, 1);
    }
  },
  SET_SELECTED_VDO: (state, vdo_id) => {
    state.selected_ids = []
    state.selected_ids.push(...vdo_id)
  },
  VERIF_ERRORS: (state, list_id) => {
    state.vdo_errors = {
      vdo_name: [],
      vdo_timeline: [],
      ident_id: [],
      back_id: [],
      spk_id: [],
      music_id: [],
      global: [],
    };
    list_id.forEach((id) => {
      let index = state.videos.findIndex((vdo) => id == vdo._id);

      if (index != "-1") {
        let video = state.videos[index];


        if (video.vdo_name == "") {
          if (!state.vdo_errors.vdo_name.includes(video._id)) {
            state.vdo_errors.vdo_name.push(video._id);
          }

          state.vdo_errors.global.push(video._id);
        }


        if (video.vdo_duration == undefined || video.vdo_duration < 0) {
          if (!state.vdo_errors.vdo_timeline.includes(video._id)) {
            state.vdo_errors.vdo_timeline.push(video._id);
          }

          state.vdo_errors.global.push(video._id);
        }
        // Add timeline vérification

        if (verifGlobal.isEmpty(video.ident_id)) {
          if (!state.vdo_errors.ident_id.includes(video._id)) {
            state.vdo_errors.ident_id.push(video._id);
          }
          state.vdo_errors.global.push(video._id);
        }

        if (verifGlobal.isEmpty(video.back_id)) {
          if (!state.vdo_errors.back_id.includes(video._id)) {
            state.vdo_errors.back_id.push(video._id);
          }
          state.vdo_errors.global.push(video._id);
        }

        if (verifGlobal.isEmpty(video.spk_id)) {
          if (!state.vdo_errors.spk_id.includes(video._id)) {
            state.vdo_errors.spk_id.push(video._id);
          }
          state.vdo_errors.global.push(video._id);
        }

        if (verifGlobal.isEmpty(video.music_id)) {
          if (!state.vdo_errors.music_id.includes(video._id)) {
            state.vdo_errors.music_id.push(video._id);
          }
          state.vdo_errors.global.push(video._id);
        }
      }
    });
  },

  UNSET_FIELD: (state, data) => {
    let index = state.videos.findIndex((vdo) => vdo[data.field] == data.value)
    while (index != "-1") {
      state.videos[index].ident_id = undefined;
      index = state.videos.findIndex((vdo) => vdo[data.field] == data.value)
    }

  },
  ASSIGN_MEDIAS: (state, medias) => {
  //  console.log('assign  ', medias)
    state.videos.forEach((vdo, index) => {
      if (vdo.identity != undefined) {
        let id = vdo.identity.ident_logo
        let media = medias.filter((media) => { return media._id === id })[0]
        if (media != undefined) {
          state.videos[index].identity.blob = media.blob
        }

      }

      if (vdo.background != undefined) {
        let id = vdo.background.back_thumbnail
        let media = medias.filter((media) => { return media._id === id })[0]
        if (media != undefined) {
          state.videos[index].background.blob = media.blob
        }

      }

      if (vdo.speaker != undefined) {
        let id = vdo.speaker.spk_thumbnail
        let media = medias.filter((media) => { return media._id === id })[0]
        if (media != undefined) {
          state.videos[index].speaker.blob = media.blob
        }

      }

    })

  }

}

