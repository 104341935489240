export default [
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile/Profile.vue'),
        beforeEnter: (to, from, next) => {
          if (to.path == "/profile" || to.path == "/profile/"){
            next({
              name: 'Profile.info'
            })
          } else {
            next()
          }
        },
        children: [
          {
            path:'informations',
            name:'Profile.info',
            component: () => import('../views/Profile/subViews/information.vue')
          },
          {
            path:'password',
            name:'Profile.pwd',
            component: () => import('../views/Profile/subViews/password.vue')
          },
          {
            path:'billing',
            name:'Profile.billing',
            component: () => import('../views/Profile/subViews/billing/billing.vue')
          }
        ] 
        
    }
]