export default {
  videos: [],
  selected_ids: [],
  vdo_errors: {
    vdo_name: [],
    vdo_timeline: [],
    ident_id: [],
    back_id: [],
    spk_id: [],
    global: [],
  },
  usr_id: null,
  remainingCredit : 0
}