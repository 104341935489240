import axios from 'axios'

//ROUTER
import router from "./../../../router/index"

//LIBS
import snackbar from "./../../../components/Object/snackbar"

const state = {
  token: null,
  user: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    state.token = token
  },
  SET_USER_INFO: (state, usr_info) => {
    state.user = usr_info
  },
  SET_USER_ID: (state, usr_id) => {
    state.user._id = usr_id
  }
}

const getters = {
  getToken: state => state.token,
  getCurrentUser: state => state.user,
  getCurrentUsrId: state => state.user._id,
  isAdmin: state => state.user.usr_role == 0 ? true : false,
  remainingCredit: (state) => {
    return state.user.usr_credit || 0
  },
  subscriptionExpiration: (state) => {
    return state.user.usr_subscription_end
  }
}

const actions = {
  set_tokenAndUser: ({ commit }, data) => {
    commit('SET_TOKEN', data.token)
    commit('SET_USER_INFO', data.usr_info)
  },

  login: ({ commit }, { email, password, query }) => {
    return axios
      .post("/auth", {
        usr_email: email,
        usr_pwd: password,
      })

      .then((res) => {
        commit('SET_TOKEN', res.data.token)
        commit('SET_USER_INFO', res.data.usr_info)
        
        /*
        //Refirect to api after login if query redirect
        if (query != undefined && query.redirectOnLogged) {

          let redirectQuery = "";
          if (query.redirectQuery == "user_id") {
            redirectQuery = `?user_id=${res.data.usr_info._id}`
          }

          console.debug(`[STORE Login] Router redirect to ${query.redirectOnLogged}${redirectQuery}`)
          console.log(router)
          router.replace(`${query.redirectOnLogged}${redirectQuery}`);
        } else {
          router.push({
            name: "Home",
            query : query
          });
        }
        */

        router.push({
          name: "Home",
          query : query
        });
      })
      .catch((err) => {

        if (err.response != undefined) {
          snackbar.show({
            text: err.response.data,
            pos: snackbar.pos,
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[0],
          });
        }
      });

  },
  storeTokenFromCookie : ({ commit, dispatch }, tokenFromCookie ) =>{
    //1. Storing token cookie from in state
    commit('SET_TOKEN', tokenFromCookie)

    //2. Ping api to get usr_id (/auth/ping)
    return axios.get(`/auth/ping`)
      .then(res => {
        //3. Set usr_id in store and "refresh_user_info"
        commit('SET_USER_ID', res.data.usr_id)
        return dispatch('refresh_user_info')
      })
  },
  register: ({ commit }, { email, password, firstname, lastname, query }) => {
    if (process.env.desactiveRegisterButton != undefined && process.env.desactiveRegisterButton == "true"){
      throw "dev mode - no registration"
    }

    axios.post("/auth/register", {
      usr_email: email,
      usr_pwd: password,
      usr_firstname: firstname,
      usr_lastname: lastname
    })
      .then((res) => {
        commit('SET_TOKEN', res.data.token)
        //console.log("res.data.usr_info : ", res.data.usr_info)
        commit('SET_USER_INFO', res.data.usr_info)
        //console.log('query : ', query)

        //REDIRECT TO HOME PAGE (WITH QUERY IF THERE IS REDIRECT AFTER - FOR PAYEMENT)
        
        router.push({
          name: "Home",
          query : query
        });
      })
      .catch((err) => {
        console.warn(err.response)

        let errorMessage = (typeof(err.response.data) == "string" ? err.response.data : "Une erreur est survenue, veuillez nous contacter")
        if (err.response.data && err.response.data.errors && err.response.data.errors.message){
          errorMessage = err.response.data.errors.message
        }

        snackbar.show({
          text: errorMessage,
          pos: snackbar.pos,
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[0],
        });
      })

  },
  forgotPwd: ({ commit }, email) => {
    return axios.post("/auth/forgot", {
      usr_email: email
    })
      .then((res) => {

        snackbar.show({
          text: res.data,
          pos: snackbar.pos,
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[2],
        });
        return Promise.resolve()


      })
      .catch((err) => {

        snackbar.show({
          text: err.response.data,
          pos: snackbar.pos,
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[0],
        });
        return Promise.reject()
      })
  },
  refresh_user_info: (({ state, commit }) => {
    return axios.get(`/users/${state.user._id}`)
      .then(res => {
        commit('SET_USER_INFO', res.data)
      })
      
  })

}

export default {
  state,
  mutations,
  actions,
  getters
}