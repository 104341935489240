export default {
    //FONTS
    font_name_list_selector : ( state ) => {
        var result = state.fonts.flatMap(font =>{
            if (font.loading == 2 && font.font_fName != undefined){
                return {
                    name : font.font_fName.value,
                    value : font._id
                }
            } else {
                return null
            }
        }).filter(font =>{
            if (font == null){
                return false
            } else {
                return true
            }
        })
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        return result
    },
    font : (state) => (font_name_or_font_id) => {
        return state.fonts.filter(font=>{
            if (font.font_fName.value == font_name_or_font_id || font._id == font_name_or_font_id){
                return true
            } else {
                return false
            }
        })
    },
    fonts_loading : (state) => { return state.fonts_loading }
}