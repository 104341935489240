<template>
  <div>
    <!-- Right Nav Bar -->
    <div id="nav" class="home">
      <a href="~" 
        ><img src="./assets/yopbox_logo_white.svg" style="margin-top: 30px"
      /></a>
      <br />
      <router-link to="/">
        <img src="./assets/home_white.png" style="margin-top: 40px" />
      </router-link>
      <br />
      <router-link to="/identity">
        <img src="./assets/identity.svg" style="margin-top: 20px" />
      </router-link>
      <br />
      <router-link to="/profile">
        <img
          v-if="loginView == false"
          src="./assets/profil_white.svg"
          style="margin-top: 20px"
        />
      </router-link>
      <router-link to="/admin" v-if="isAdmin">
        <img src="./assets/admin.png" class="admin" />
      </router-link>

      <img
        v-if="loginView == false"
        src="./assets/logout_white.png"
        class="disconnect"
        @click="disconnect"
      />
    </div>

    <div
      class="router-view"
      v-bind:class="{
        fullScreenMode: loginView,
        withNavBar: showNavigationBar,
      }"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// LIBS
import $ from "jquery";
import emitter from "tiny-emitter/instance";
import axios from "axios";

//STORE
import Vuex from "vuex";

// CUSTOMS LIBS
import snackbar from "./components/Object/snackbar";

export default {
  name: "App",
  data() {
    return {
      click_location: "home",
      token: "eaeraere",
      showNavigationBar: false,
    };
  },
  computed: {
    ...Vuex.mapGetters(["getToken", "getCurrentUser", "isAdmin"]),

    loginView: function () {
      if (this.$route.path != "/login") {
        setTimeout(() => {
          if (this.$route.path != "/login") {
            this.showNavigationBar = true;
          }
        }, 0);

        return false;
      } else {
        this.showNavigationBar = false;
        return true;
      }
    },
  },
  watch: {
    showNavigationBar: function (val) {
      if (val == true) {
        $(":root").css({
          "--nav_width": "70px",
        });
      } else {
        $(":root").css({
          "--nav_width": "0px",
        });
      }
    },
  },
  beforeUnmount() {
    //this.$cookies.set("destroy", "test");
  },
  methods: {
    ...Vuex.mapActions(["set_tokenAndUser", "refresh_user_info"]),
    disconnect() {
      this.set_tokenAndUser({ token: null, usr_info: {} });
      this.$cookies.remove("token");
      this.$cookies.remove("currentUser");
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    if (
      this.$cookies.get("token") != undefined &&
      this.$cookies.get("currentUser") != undefined
    ) {
      let token = this.$cookies.get("token");
      let usr_info = this.$cookies.get("currentUser");

      this.$nextTick(() => {
        this.set_tokenAndUser({ token, usr_info });

        //this.$cookies.remove("token");
        //this.$cookies.remove("currentUser");
      });
    }

    /* //PLUS UTILE ETANT DONNÉ QU'UN NE SUPPRIME PLUS LES COOKIES
    let that = this;
    $(window).bind("beforeunload", function (event) {
      event.preventDefault();

      if (that.getToken != null) {
        that.$cookies.set("null", "false");
        that.$cookies.set("token", that.getToken);
        that.$cookies.set("currentUser", JSON.stringify(that.getCurrentUser));
      }
    });
    */
    
    /*
    axios.interceptors.response.use(undefined, (err) => {

      if (err.request.status == 0) {

        snackbar.show({
          text: "Problème de connexion au serveur, veuillez réessayer ultérieurement",
          pos: snackbar.pos,
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[1],
        });
        this.set_tokenAndUser({ token: null, usr_info: {} });
        this.$cookies.remove("token");
        this.$router.push({
          name: "Login",
        });
      }

      if (err.response != undefined && err.response.status == 401) {
        snackbar.show({
          text: "Session expirée, veuillez vous reconnecter",
          pos: snackbar.pos,
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[1],
        });

        this.set_tokenAndUser({ token: null, usr_info: {} });
        this.$cookies.remove("token");
        this.$router.push("/login");
      }
      return Promise.reject(err);
    });
    */

    axios.interceptors.request.use(
      (data) => {
        return data;
      },
      (err) => {
        console.log(err);

      }
    );

    emitter.emit("click_location", this.click_location);

    emitter.on("click_location", (click_location) => {
      //console.debug("[app.vue receiver] " + click_location)
      this.click_location = click_location;
    });

    document.addEventListener("click", (evt) => {
      // HOME CLICK DETECTION
      let isHome = false;
      /*
            console.log($(evt.target))
            if ($(evt.target).hasClass("click_to_home")) {
                isHome = true;
            }

            console.log("isHome : ", isHome)
            */

      //PANEL CLICK DETECTION
      let isPanel1;
      $(evt.target)
        .parents()
        .each(function () {
          if (
            $(this).attr("class") != undefined &&
            $(this).attr("class").length > 0 &&
            $(this).attr("class").split(" ").includes("panel")
          ) {
            isPanel1 = true;
          }
        });

      let click_evt_set;

      if (isPanel1 == true) {
        click_evt_set = "panel1";
      } else if (isHome == true) {
        click_evt_set = "home";
      }

      if (this.click_location != click_evt_set && click_evt_set != undefined) {
        this.click_location = click_evt_set;
        //console.debug("[app.vue click listenner] " + click_evt_set)
        emitter.emit("click_location", this.click_location);
      }
    });

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "7a65eacd-78fc-49c6-83ea-36b83d778c8c";
    (function () {
      var d = document;
      var s = d.createElement("script");
      s.setAttribute("id", "crispChat");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  },
};
</script>


<style src="./../node_modules/node-snackbar/dist/snackbar.min.css" charset="utf-8">
</style>

<style src="./styles/global.css">
</style>

<style src="./styles/inputs.css">
</style>

<style src="./styles/buttons.scss" lang="scss">
</style>

<style src="./styles/cards.scss" lang="scss">
</style>

<style src="./styles/panel.css">
</style>

<style src="./styles/nav.css">
</style>

<style src="./styles/checkbox.css">
</style>

<style src="./styles/trash.css">
</style>

<style src="./styles/transitions.css">
</style>

<style lang="css" scoped>
.router-view {
  position: absolute;
  z-index: var(--zindex_routerView);
  float: left;
  height: 100%;
  left: 0px;
  width: 100%;
  border-radius: 0px;
  filter: drop-shadow(-10px 0px 10px #0000002c);
  background-color: var(--color_background);
  /* Transition */
  transition: left 0.5s var(--easeEnter), border-radius 500ms ease-in-out,
    width 500ms ease-in-out;
}

.router-view.fullScreenMode {
  left: 0px;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
}

.router-view.withNavBar {
  --left_offset: 2px;
  left: calc(var(--nav_width) - var(--left_offset));
  width: calc(100% - var(--nav_width) + var(--left_offset));
  border-radius: var(--border_radius) 0px 0px var(--border_radius);
}
</style>
